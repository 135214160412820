<template>
  <section>
    <div class="spacecard border-0" v-if="course">
      <b-row class="align-items-center">
        <b-col cols="auto">
          <b-button class="btn-themed back-btn-squared btn-white" :to="{ name: 'StudentLightCourseModules', params: { courseId } }">
            <BackSvg/>
          </b-button>
        </b-col>
        <b-col>
          <h2>
            {{ module.title }}
          </h2>
        </b-col>
        <b-col cols="auto">
          <ProgressPie size="small" :progress="module.progress"/>
        </b-col>
      </b-row>
      <hr/>
      <div>
        <b-row class="align-items-center">
          <b-col lg="auto" class="d-md-block d-none">
            <div class="lesson-switch-wrapper">
              <router-link v-for="l in lessons"
                           class="d-block"
                           :to="{ name: 'StudentLightCourseLessons', params: { courseId, moduleId, lessonId: l.id } }"
                           :key="`lesson-${l.id}`">
                {{ l.title }}
              </router-link>
            </div>
          </b-col>
          <b-col>
            <div v-for="l in lessons" :key="`l-${l.id}`">
              <LightCourseAccomplishmentMap :lesson="l"
                                            @headerClicked="headerClicked(l)"
                                            class="mb-2"
                                            :body="lesson && lesson.id === l.id"
                                            :exercises="exercises"/>
            </div>

          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import BackSvg from '@/assets/svg/back_3.svg'
import ProgressPie from "../../../parts/general/ProgressPie.vue"
import { StudentPageService } from "../../../../services/api.service"
import LightCourseAccomplishmentMap from "../../../parts/courses/LightCourseAccomplishmentMap.vue"

export default {
  name: "StudentLightCourseLessons",
  components: {
    LightCourseAccomplishmentMap,
    BackSvg,
    ProgressPie
  },
  data() {
    return {
      course: null,
      module: null,
      lessons: [],
      lesson: null,
      exercises: []
    }
  },
  metaInfo() {
    return {
      title: this.$t('lessons'),
    }
  },
  computed: {
    courseId() {
      return this.$route.params.courseId
    },
    moduleId() {
      return this.$route.params.moduleId
    },
    lessonId() {
      return this.$route.params.lessonId
    },
  },
  methods: {
    async loadModuleData() {
      const res = await StudentPageService.getLightCourseModuleData(this.moduleId)
      this.course = res?.data?.data?.course || null
      this.module = res?.data?.data?.module || null
      this.lessons = res?.data?.data?.lessons || []
    },
    async loadLessonData() {
      const res = await StudentPageService.getLightCourseLessonData(this.lessonId)
      this.lesson = res?.data?.data?.lesson || null
      this.exercises = res?.data?.data?.exercises || []
    },
    headerClicked(lesson) {
      this.$router.push({
        name: 'StudentLightCourseLessons',
        params: { ...this.$router.currentRoute.params, lessonId: lesson.id }
      })
    }
  },
  async mounted() {
    await this.loadModuleData()
    if(!this.lessonId && this.lessons.length) {
      let lesson = this.lessons.find(lesson => lesson.progress < 100)
      if(!lesson) lesson = this.lessons[0]
      await this.$router.replace({ params: { ...this.$router.currentRoute.params, lessonId: lesson.id } })
    }
    if(this.lessonId) await this.loadLessonData()
  },
  watch: {
    lessonId() {
      this.loadLessonData()
    }
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
  margin-left: 6px;
}
.lesson-switch-wrapper {
  background: #fbfcfc;
  border-radius: 9px;
  width: 295px;
  padding: 0 24px;
  a {
    color: #8DEBFFB2;
    font-weight: 700;
    font-size: 20px;
    padding: 14px;
    text-align: center;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    &.router-link-active, &:hover {
      color: #43B4EB;
      border-bottom: 2px solid #43B4EB;
    }
  }
}

@media (max-width: 991px) {
  .spacecard {
    padding: 12px 12px;
  }
  .lesson-switch-wrapper {
    width: 100%;
  }
}
</style>

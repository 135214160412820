<template>
  <div class="accomplishment-map" v-if="lesson">
    <div class="title text-center" :style="headerStyle" @click="headerClicked" :class="{ 'pointer': !body }">
      <b-row class="align-items-center">
        <b-col class="b-col" cols="2" lg="1">
          <div class="heart-text"><HeartSvg/>{{ lesson.livesLeft }}</div>
        </b-col>
        <b-col class="b-col">
          {{ lesson.subtitle ? lesson.subtitle : lesson.title }}
        </b-col>
        <b-col class="b-col" cols="2" lg="1"></b-col>
      </b-row>
    </div>
    <transition name="smooth">
      <div class="map py-5" v-if="body">
        <div class="map-back" :style="mapStyle"></div>
        <div class="map-content">
          <div class="exercises">
            <div v-for="(e, eindex) in exercises"
                 :key="`exercise-${e.id}`"
                 :class="{ 'ml-auto': isEven(eindex) }"
                 class="exercise">
              <b-row class="no-gutters align-items-center">
                <b-col :order="isEven(eindex) ? 1 : 0">
                  <div class="exercise-title">{{ e.title }}</div>
                </b-col>
                <b-col cols="auto"  :order="isEven(eindex) ? 0 : 1">
                  <b-button class="exercise-img-wrap"
                            :disabled="e.locked"
                            @click="launchExercise(e)">
                    <img :src="`/images/light/${exerciseLogo(e)}`" class="btn-img" alt=""/>
                  </b-button>
                </b-col>
              </b-row>
              <div v-if="(eindex + 1) < exercises.length" class="flow-road"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import HeartSvg from '@/assets/svg/heart.svg'

export default {
  name: "LightCourseAccomplishmentMap",
  components: {
    HeartSvg
  },
  props: {
    lesson: {
      type: Object,
      default: null
    },
    exercises: {
      type: Array,
      default: () => []
    },
    body: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    headerStyle() {
      return {
        'color': this.lesson.text_color || '#000000',
        'backgroundColor': this.lesson.back_color || '#dddddd',
      }
    },
    mapStyle() {
      return {
        'backgroundColor': this.lesson.back_color || '#dddddd',
      }
    }
  },
  methods: {
    exerciseLogo(e) {
      if(e.locked) return 'locked.png'
      if(e.progress === 'finished') return 'passed.png'
      return 'start.png'
    },
    isEven(index) {
      return index % 2 !== 0
    },
    async launchExercise(exercise) {
      await this.$router.push({ name: 'StudentLightCourseLessonRoom', params: { materialId: exercise.id } })
    },
    headerClicked() {
      if(this.body) return
      this.$emit('headerClicked')
    }
  }
}
</script>

<style scoped lang="scss">
.accomplishment-map {
  .title {
    font-size: 36px;
    font-weight: 900;
    padding: 12px;
    border-radius: 8px;
    font-family: 'Nunito';
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  }
  .heart-text {
    background: #fff;
    border-radius: 100px;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF8D4E;
    font-size: 24px;
    svg {
      fill: #FF8D4E;
      width: 20px;
      margin-right: 4px;
    }
  }
  .map {
    border-radius: 8px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
    .map-back {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.3;
      z-index: 1;
    }
    .map-content {
      position: relative;
      z-index: 2;
    }
    .exercises {
      .exercise {
        position: relative;
        &:nth-of-type(even) {
          .exercise-img-wrap {
            left: 10px;
          }
          .flow-road {
            transform: rotate(-45deg);
            right: auto;
            left: -30px;
          }
          .exercise-title {
            padding-left: 9px;
            padding-right: 50px;
            text-align: right;
          }
        }
        width: 50%;
      }
    }
    .btn-img {
      width: 60px;
    }
    .exercise-title {
      background: white;
      color: #73969D;
      font-size: 20px;
      font-weight: 700;
      padding: 9px;
      position: relative;
      z-index: 2;
      padding-left: 50px;
      white-space: nowrap;
    }
    .exercise-img-wrap {
      position: relative;
      border: none;
      z-index: 2;
      padding: 10px;
      border-radius: 100%;
      background: white;
      left: -10px;
      outline: none;
      img {
        transition: all 0.1s;
      }
      &:active {
        img {
          transform: scale(0.8);
        }
      }
      &.disabled {
        opacity: 1;
      }
    }
    .flow-road {
      height: 15px;
      width: 60px;
      background: white;
      transform: rotate(45deg);
      position: absolute;
      z-index: 1;
      transform-origin: center;
      right: -30px;
    }
  }
}

@media(max-width: 768px) {
  .accomplishment-map {
    .title {
      font-size: 24px;
      padding: 4px;
      box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
    }
    .heart-text {
      width: 36px;
      height: 36px;
      font-size: 16px;
      svg {
        width: 16px;
      }
    }
    .map {
      .exercises {
        .exercise {
          &:nth-of-type(even) {
            .exercise-title {
              padding-right: 8px;
              padding-left: 0;
            }
            .exercise-img-wrap {
              left: 8px;
            }
            //      .flow-road {
            //        transform: rotate(90deg);
            //        left: 14px;
            //      }
          }
          //    width: 80%;
        }
      }
      .exercise-title {
        padding-left: 8px;
        font-size: 15px;
        padding-right: 0;
      }
      .btn-img {
        width: 40px;
      }
      .exercise-img-wrap {
        left: -8px;
        padding: 8px;
      }
      //.flow-road {
      //  transform: rotate(90deg);
      //  right: 14px;
      //}
    }
  }
}
</style>
